.portfolio-page {
    position: sticky;
    height: 150%;
    overflow: auto;
    cursor: default;
    background-color: #2d2d2d;

    h1.page-title {
        margin-left: 100px;
        color: #ffd700;
    }

    .flex {
        margin-bottom: 8rem;
    }

    .single {
        display: grid;
        grid-template-columns: auto;
        justify-content: center;
        align-items: center;
    }

    .wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(12rem, 25rem));
        gap: 3rem;
        max-height: 100px;
        justify-content: center;
        margin-bottom: 8rem;
    }
}