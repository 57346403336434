.contact-form {
  width: 100%;
  margin-top: 20px;

  ::placeholder {
    color: #b5b5b5;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
  background-color: #696969;
  background: #696969;
};

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: default;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }
  

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #696969;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  input:active,
  textarea:focus,
  textarea:active, 
  input:focus {
    outline:inset
  }


  textarea {
    width: 100%;
    border: 0;
    background: #696969;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: #ffd700;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;

    &:hover {
      background: #ffd700;
      color: #333;
      cursor: pointer;
    }
    
  }
}
.text-zone {
  width: 500px;

  .contacttext{
    font-size: 18px;
    font-weight: bolder;
  }
}

@media screen and (max-width: 1200px) {
  .map-wrap {
    float: none;
    margin: 0;
    width: 100%;
    height: 400px;
  }
  
  .info-map {
    display: none;
  }
}

p {
  animation: fadeIn 1s 1.8s forwards;
}