/*   color variables */
$clr-primary: #ffd700;
$clr-gray300: #424242;

/*   border radius */
$radius: 0.2rem;

.cardSpan2 {
  display: flex;
  justify-content: space-evenly;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.card2 {
  overflow: hidden;
  box-shadow: 0 2px 10px $clr-gray300;
  border-radius: 4px;
  width: 100rem;
  cursor: pointer;
  transition: transform 200ms ease-in;
  background-color: #181818;

  &__body {
    justify-content: center;
    text-align: center;
    margin: 1rem;
  }

  &__image {
    height: 100%;
    width: 45rem;
    object-fit: contain;
    float: right;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }

  &__title {
    padding: 1rem;
    color: #ffd700;
    font-size: x-large;
  }

  &__description {
    padding: 0 1rem;
    left: 0;
    font-size: 17.5px;
    font-weight: bolder
  }

  &__btn {
    align-items: center;
    padding: 1rem;
    font-weight: bold;
    font-size: larger;
    margin: 1rem;
    border: 2px solid $clr-primary;
    background: transparent;
    color: $clr-primary;
    border-radius: $radius;
    transition: background 200ms ease-in, color 200ms ease-in;
  }

  &__btn a {
    color: #ffd700;
    text-decoration: none;
  }

  &:hover {
    transform: scale(1.02);
    cursor: default
  }

  &:hover &__btn {
    background: $clr-primary;
    color: black;
    cursor: pointer;
  }
}