.skillset {
  color: #ffd700;
}

.stage-cube-cont {
  width: 50%;
  height: 100%;
  top: 0;
  padding-top: 11%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.selfPhoto {
  height: 200px;
  position: absolute;
  margin-top: 9%;
  margin-left: calc(50% - 40px);
  z-index: -2;

  img {
    height: 200px;
    box-shadow: 0 2px 5px #424242;
  }
}


.cubespinner {
  animation-name: spincube;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 12s;
  transform-style: preserve-3d;
  transform-origin: 100px 100px 0;
  margin-top: 20%;
  margin-left: calc(50% - 50px);

  div {
    position: absolute;
    width: 200px;
    height: 200px;
    border: 1px solid #ccc;
    background: rgba(255, 255, 255, 0.4);
    text-align: center;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 0px lightyellow;
  }

  .face1 {
    transform: translateZ(100px);
    color: #dd0031;
  }

  .face2 {
    transform: rotateY(90deg) translateZ(100px);
    color: #f06529;
  }

  .face3 {
    transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
    color: #28a4d9;
  }

  .face4 {
    transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
    color: #5ed4f4;
  }

  .face5 {
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
    color: #efd81d;
  }

  .face6 {
    transform: rotateX(-90deg) translateZ(100px);
    color: #ec4d28;
  }
}


@keyframes spincube {

  from,
  to {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }

  16% {
    transform: rotateY(-90deg) rotateZ(90deg);
  }

  33% {
    transform: rotateY(-90deg) rotateX(90deg);
  }

  50% {
    transform: rotateY(-180deg) rotateZ(90deg);
  }

  66% {
    transform: rotateY(-270deg) rotateX(90deg);
  }

  83% {
    transform: rotateX(90deg);
  }
}

.text-zone {
  cursor: default;

  .flat-button {
    background-color: #3f3f3f;
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    white-space: nowrap;
    margin-right: 10px;

    &:hover {
      color: #022c43;
      background-color: #ffd700;
    }
  }

}

p {
  animation: fadeIn 1s 1.8s backwards;
}