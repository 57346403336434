.logo-container {
  z-index: 0;
  width: 800px;
  height: 609px;
  opacity: 0;
  position: absolute;
  top: 5%;
  right: 0;
  bottom: 0;
  left: 30%;
  margin: auto;
  z-index: -999;

  .solid-logo {
    position: absolute;
    left: 0;
    width: 900px;
    z-index: -999;
    height: auto;
  }
}
