
.home-page {
  .text-zone {
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
    cursor: default;
    user-select: none;

    .home-buttons {
      justify-content: space-evenly;
    }
  }

  h1 {
    color: #ffd700;
    font-size: 56px;
    line-height: 53px;
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;
    z-index: 2;

    &:before {
      content: '<h1>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: -40px;
      left: -15px;
      opacity: 0.6;
    }

    &:after {
      content: '<h1/>';
      font-family: 'La Belle Aurore', cursive;
      color: #ffd700;
      font-size: 18px;
      position: absolute;
      margin-top: 18px;
      margin-left: 20px;
      animation: fadeIn 0s 0s backwards;
      opacity: 0.6;
    }

    img {
      margin-left: 20px;
      opacity: 0;
      width: 46px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    background-color: #3f3f3f;
    color: #ffd700;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #ffd700;
    margin-top: 25px;
    float: left;
    white-space: nowrap;
    margin-right: 10px;

    &:hover {
      color: #022c43;
      background-color: #ffd700;
    }
  }
}



// @media screen and (max-width: 1200px) {
//   .tags,
//   .home-page h1:before,
//   .home-page h1:after {
//     display: none;
//   }

//   .home-page .text-zone {
//     position: initial;
//     width: 100%;
//     transform: none;
//     padding: 10px;
//     box-sizing: border-box;
//   }

//   .home-page .flat-button {
//     float: none;
//     display: block;
//     margin: 20px auto 0 auto;
//     width: 124px;
//   }

//   .logo-container {
//     position: relative;
//     width: 100px;
//     height: auto;
//     top: 50px;
//     right: 0;
//     box-sizing: border-box;
//     margin: auto;
//     left: 0;
//   }

//   .logo-container svg {
//     position: absolute;
//     top: auto;
//     right: auto;
//     bottom: auto;
//     left: 0;
//     margin: auto;
//   }
// }