// @import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");

/*   color variables */
$clr-primary: #ffd700;
$clr-gray300: #424242;

/*   border radius */
$radius: 0.2rem;

.cardSpan3 {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.card3 {
  overflow: hidden;
  box-shadow: 0 2px 10px $clr-gray300;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 200ms ease-in;
  background-color: #181818;;

  &__body {
    margin: 1rem;
  }

  &__image {
    height: 20rem;
    width: 100%;
    object-fit: cover
  }

  &__title {
    padding: 1rem;
    color:#ffd700;
  }

  &__description {
    padding: 0 1rem;
    font-size: 13px;
  }

  &__btn {
    align-items: center;
    padding: 1rem;
    font-weight: bold;
    font-size: 1rem;
    margin: 1rem;
    border: 2px solid $clr-primary;
    background: transparent;
    color: $clr-primary;
    border-radius: $radius;
    transition: background 200ms ease-in, color 200ms ease-in;
  }

  &__btn a {
    color:#ffd700;
    text-decoration: none;
  }

  &:hover {
    transform: scale(1.02);
    cursor: default
  }

  &:hover &__btn {
    background: $clr-primary;
    color: #022c43;
    cursor: pointer;
  }
}

