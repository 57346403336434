html {
  font-size: 62.5%;
}

body {
  height: 100vh;
  width:100vw;
  min-height: 600px;
  overflow: default;
  margin: 0;

  font: 300 11px/1.4 'Helvetica Neue', ' sans-serif';
  color: #444;
  background: #2d2d2d;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @media screen and (max-width: 768px) {
  body {
    font-size: 50px !important;
  } 
} */